import React, {useState} from "react";
import { DropzoneArea } from "mui-file-dropzone";
import { useDebounce } from 'use-debounce';
import axios from 'axios';


export default function UploadRaspis(props) {
    const handleChange = (files) => {
        if(files.length > 0){
            upload(files);
        }
    }

    const upload = async(files) => {
        try {
            let formData = new FormData();
            for(let i=0;i<files.length;i++){
                formData.append("file", files[i]);
            }
            let res = await axios.post(`/json/dashboard/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Файл загружен", "success");
                setKey(Math.random());
            } else {
                props.showSnackbar("Ошибка добавления файла", "error");
                console.log("error")
            }
        }catch (e) {
            props.showSnackbar("Ошибка добавления файла", "error");
            setKey(Math.random());
            console.log(e)
        }
    }


    const [key, setKey] = useState(0);
    const [debounceKey] = useDebounce(key, 1000);

    return (
        <>
            <DropzoneArea
                key={debounceKey}
                onChange={handleChange}
                acceptedFiles={['application/JSON']}
                dropzoneText={"Перетащите сюда файл с расписанием или кликните"}
                getFileAddedMessage={(fileName)=> `Файл ${fileName} добавлен, начинаем загрузку`}
            />
        </>
    );
}