import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";


export default function GroupSelect(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    const getGroups = async () => {
        try {
            let res = await axios.get(`/json/dashboard/groups`);
            if (!!res.data.status && res.data.status === "ok") {
                return res.data.rows;
            } else {
                console.log("error")
                return [];
            }
        }catch (e) {
            console.log(e)
            return [];
        }
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            let rows = await getGroups();

            if (active) {
                setOptions([...rows]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="groupsList"
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option}
            options={options}
            loading={loading}
            onChange={props.onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Выбор группы"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
