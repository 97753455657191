import React, {useState} from 'react';
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import GroupSelect from './GroupSelect';
import TeacherSelect from './TeacherSelect';
import RoomSelect from './RoomSelect';
import ruLocale from "date-fns/locale/ru";
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


 const locales = {
  'ru': ruLocale,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})


export default function Viewer(props) {
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(null);
    const [lastLoad, setLastLoad] = useState([]);

    const loadEvent= async (id) => {
        try {
            let res = await axios.get(`/json/dashboard/getEvent?id=${id}`);
            if (!!res.data.status && res.data.status === "ok") {
                setEvent(res.data.event);
            } else {
                props.showSnackbar("Ошибка загрузки занятия", "error");
                console.log("error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteEvent = async (id) => {
        try {
            let res = await axios.get(`/json/dashboard/removeEvent?id=${id}`);
            if (!!res.data.status && res.data.status === "ok") {
                setEvent(null);
                load(lastLoad[0], lastLoad[1]);
            } else {
                props.showSnackbar("Ошибка загрузки занятия", "error");
                console.log("error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deleteEventAll = async (file) => {
        try {
            let res = await axios.get(`/json/dashboard/removeEventAll?file=${file}`);
            if (!!res.data.status && res.data.status === "ok") {
                setEvent(null);
                load(lastLoad[0], lastLoad[1]);
            } else {
                props.showSnackbar("Ошибка загрузки занятия", "error");
                console.log("error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const load= async (mode, value) => {
        try {
            setEvent(null);
            setLastLoad([mode,value]);
            let res = await axios.get(`/json/dashboard/events?mode=${mode}&value=${value}`);
            if (!!res.data.status && res.data.status === "ok") {
                let dt = res.data.data.map((ev) => {
                    let myev = ev;
                    myev.start = new Date(ev.start);
                    myev.end = new Date(ev.end);
                    return myev;
                });
                
                setEvents(dt);
            } else {
                props.showSnackbar("Ошибка загрузки занятий", "error");
                console.log("error")
            }
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <Container >
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
            </Box>
            <Grid container spacing={2} >
                <Grid item xs={4} ><GroupSelect onChange={(e,newValue)=> load('group',newValue)} /></Grid>
                <Grid item xs={props.client && lastLoad[0]==="teacher" ? 3 : 4} >
                    <TeacherSelect
                     onChange={(e,newValue)=> load('teacher',newValue)}
                      />
                </Grid>
                    {props.client && lastLoad[0]==="teacher" && 
                    <Grid item xs={1} ><IconButton color="secondary" aria-label="экспорт" as="a" href={`/json/ical?teacher=${lastLoad[1]}`} >
                        <CalendarMonthIcon/>
                    </IconButton></Grid>}
                <Grid item xs={4} ><RoomSelect onChange={(e,newValue)=>load('room', newValue)} /></Grid>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={12} md={!!event ? 8 : 12 }>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                defaultView="month"
                style={{ height: "100vh", marginTop: "20px" }}
                defaultDate={new Date()}
                selectable={true}
                culture="ru"
                messages={{
                    next: "Следующий",
                    previous: "Предыдущий",
                    today: "Сегодня",
                    month: "Месяц",
                    week: "Неделя",
                    day: "День",
                    agenda: "Повестка",
                    date: "Дата",
                    time: "Время",
                    event: "Мероприятие",
                }}
                onSelectEvent={(event, e) => {
                    loadEvent(event.resource.id);
                    //console.log(event);
                }}
            />
                </Grid>
                {!!event &&
                <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                   {`${(new Date(event.time_start)).toLocaleString("ru", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'})} - ${(new Date(event.time_end)).toLocaleString("ru", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'})}`}
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {event.subject} - {event.type}
                                </Typography>
                                <Typography color="text.secondary">
                                    {event.teachers.join(',')}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="body2">
                                    {event.group} {event.subgroup != 0 ? ` (пг. ${event.subgroup})` : ""}
                                </Typography>
                                <Typography variant="body2">
                                    {event.locations.join(',')}
                                </Typography>
                                <Typography variant="body2">
                                    Неделя: {event.week}
                                </Typography>
                            </CardContent>
                            {!!!props.client &&
                            <CardActions>
                                <Button color="error" size="small" onClick={()=> deleteEvent(event._id)}>Удалить</Button>
                                <Button color="error" size="small" onClick={()=> deleteEventAll(event.file)}>Удалить все из файла {event.file}</Button>
                            </CardActions>
                            }
                        </Card>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}
