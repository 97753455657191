export const facultiesAr = [
    "Естественно-географический факультет",
    "Историко-филологический факультет",
    "Факультет иностранных языков",
    "Факультет образовательных технологий и непрерывного образования",
    "Факультет педагогики и психологии",
    "Факультет права, экономики и управления",
    "Факультет физико-математического и технологического образования",
    "Факультет физической культуры и спорта",
    "Лицей УлГПУ"
];