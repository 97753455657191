import Box from "@mui/material/Box";
import React from "react";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
    Outlet,
} from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import {TabPanel, a11yProps} from './TabPanel';
import UploadRaspis from "./UploadRaspis";
import Faculties from "./Faculties";
import Viewer from "./Viewer";
import Export from "./Export";


export default function AdminDashboard(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Разделы панели управления"
                >
                    <Tab label="Загрузка файла расписания" {...a11yProps(0)} />
                    <Tab label="Просмотр расписания в базе" {...a11yProps(1)} />
                    <Tab label="Выгрузка" {...a11yProps(2)} />
                    <Tab label="Группы" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
        <Container  style={{ width: '100vw', maxWidth: '100vw' }}>
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <UploadRaspis showSnackbar={props.showSnackbar} />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Viewer showSnackbar={props.showSnackbar} />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Export showSnackbar={props.showSnackbar} />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <Faculties showSnackbar={props.showSnackbar} />
                </TabPanel>

            </SwipeableViews>
            <Outlet />

            </Box>
        </Container>
        </>
    );
}
