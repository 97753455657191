import React, {useState, useEffect} from 'react';
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { facultiesAr } from "./facultiesAr";



export default function Export(props) {
    const [weeks, setWeeks] = useState([]);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [faculty, setFaculty] = useState();
    const [week, setWeek] = useState();


    const exportRaspis = async (id) => {
        try {
            props.showSnackbar("Процесс пошёл..", "success");
            let res = await axios.get(`/json/dashboard/exportRaspis?start=${start}&end=${end}`);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Экспорт прошёл успешно", "success");
            } else {
                props.showSnackbar("Ошибка экспорта", "error");
                console.log("error")
            }
        } catch (e) {
            console.log(e)
                props.showSnackbar("Ошибка экспорта", "error");
        }
    }

    const load= async () => {
        try {
            let res = await axios.get(`/json/dashboard/getWeeks`);
            if (!!res.data.status && res.data.status === "ok") {
                setWeeks(res.data.weeks);
                setStart(Math.min(res.data.weeks));
                setEnd(Math.max(res.data.weeks));
            } else {
                props.showSnackbar("Ошибка загрузки недель", "error");
                console.log("error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        load();
    }, []);



    return (
        <Container >
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
            </Box>
            <Typography variant="h4" component="h4" sx={{mb: 2}}>
                Выгрузка расписания для студентов
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={4} >
                    <FormControl fullWidth>
                        <InputLabel id="start-label">С недели</InputLabel>
                        <Select
                            labelId="start-label"
                            id="start"
                            value={start}
                            label="С недели"
                            onChange={(e) => setStart(e.target.value)}
                        >
                            {
                                weeks.filter((e) => end>0? e<= end : true).map((w) => (
                                    <MenuItem key={w} value={w}>{w}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} >
                    <FormControl fullWidth>
                        <InputLabel id="end-label">По неделю</InputLabel>
                        <Select
                            labelId="end-label"
                            id="end"
                            value={end}
                            label="По неделю"
                            onChange={(e) => setEnd(e.target.value)}
                        >
                            {
                                weeks.filter((e) => start>0? e>= start : true).map((w) => (
                                    <MenuItem key={w} value={w}>{w}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} >
                    <Button variant='contained' onClick={()=> exportRaspis()} disabled={start===0 || end === 0} >Выгрузить</Button>
                </Grid>
            </Grid>
            <Typography variant="h4" component="h4" sx={{mb: 2,mt: 2}}>
                Выгрузка расписания для отчётов
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={8} >
                    <FormControl fullWidth>
                        <InputLabel id="faculty-label">Факультет</InputLabel>
                        <Select
                            labelId="faculty-label"
                            id="faculty"
                            value={faculty}
                            label="Факультет"
                            onChange={(e) => setFaculty(e.target.value)}
                        >
                            {
                                facultiesAr.map((w) => (
                                    <MenuItem key={w} value={w}>{w}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} >
                    <FormControl fullWidth>
                        <InputLabel id="week-label">Неделя</InputLabel>
                        <Select
                            labelId="week-label"
                            id="week"
                            value={week}
                            label="Неделя"
                            onChange={(e) => setWeek(e.target.value)}
                        >
                            {
                                weeks.filter((e) => e%2 !== 0).map((w) => (
                                    <MenuItem key={w} value={w}>{w}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} >
                    <Button component={'a'} variant='contained' href={`/json/dashboard/exportRaspisXLSX?faculty=${faculty}&week=${week}`}  disabled={!!!faculty || !!!week} >Выгрузить Excel</Button>
                </Grid>
                <Grid item xs={4} >
                    <Button component={'a'} variant='contained' href={`/json/dashboard/exportRaspisPDF?faculty=${faculty}&week=${week}`}  disabled={!!!faculty || !!!week} >Выгрузить PDF</Button>
                </Grid>
                <Grid item xs={4} >
                    <Button component={'a'} variant='contained' href={`/json/dashboard/exportRaspisPDF?faculty=${faculty}&week=${week}&sign=true`}  disabled={!!!faculty || !!!week} >Выгрузить подпись PDF</Button>
                </Grid>
            </Grid>
        </Container>
    );
}
